export const linkResolver = doc => {
  if (!doc) return null;
  switch (doc && doc.type) {
    case "article":
      return `/article/${doc.uid}`;
    case "category_troiscouleurs":
      return `/category/${doc.uid}`;
    case "search":
      return "/search";
    case "home_troiscouleurs":
      return "/";
    default:
      return `/${doc.type}/${doc.uid}`;
  }
};

export const previewLinkResolver = () => linkResolver;
